import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'leaflet/dist/leaflet.css';
import 'assets/css/index.css';
import social from '../../src/assets/images/social.png';
import favicon from '../../src/assets/images/favicon.ico';

import 'swiper/css/swiper.min.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="shortcut icon" href={favicon} />
        <meta name="theme-color" content="#ffffff" />
        <meta
        name="description"
        content="Powerful productivity apps. So easy to use."
        />
        <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
        property="og:image"
        content={social}
        />
        <meta
        property="og:title"
        content="Formigio | Apps for those that dream. Apps for those that do."
        />
        <meta
        property="og:description"
        content="Powerful productivity apps. So easy to use."
        />
        <meta property="og:url" content="https://www.formigio.com/" />
        <link
        href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
        rel="stylesheet"
        />
        <script
        src="https://kit.fontawesome.com/4c273e6d43.js"
        crossOrigin="anonymous"
        >{``}</script>
        <script id="_agile_min_js" async type="text/javascript" src="https://neverbehind.agilecrm.com/stats/min/agile-min.js">{' '}</script>
        <script type="text/javascript" >{`
          var Agile_API = Agile_API || {}; Agile_API.on_after_load = function(){
          _agile.set_account('9u9aooo2lh4sl3koo68pj7hlqk', 'neverbehind', false);
          _agile.track_page_view();
          _agile_execute_web_rules();};
        `}
        </script>
        <script>{`
          window['_fs_debug'] = false;
          window['_fs_host'] = 'fullstory.com';
          window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
          window['_fs_org'] = '14C7KS';
          window['_fs_namespace'] = 'FS';
          (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n, p){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
        })(window,document,window['_fs_namespace'],'script','user');
        `}
        </script>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
